import React, { Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
// import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb';
// import Service from '../../../components/Service'
import About from '../../../components/About';
// import ServiceArea from '../../../components/ServiceArea'
// import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea';
// images
import about from '../../../images/about/2.jpg';
import breadcumb from '../../../images/breadcumb/1.jpg';

import './style.scss';

const aboutText = [
  { text: 'General Trading and Stationery Supplies/Private Limited' },
  // { text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum' },
];

// const services = [
//     {
//         icon: 'flaticon-parents',
//         title: 'Family Law',
//         content: 'There are many variations of passages of Lorem '
//     },
//     {
//         icon: 'flaticon-wounded',
//         title: 'Personal Injury',
//         content: 'There are many variations of passages of Lorem '
//     },
//     {
//         icon: 'flaticon-employee',
//         title: 'Business Law',
//         content: 'There are many variations of passages of Lorem '
//     },
//     {
//         icon: 'flaticon-thief',
//         title: 'Criminal Law',
//         content: 'There are many variations of passages of Lorem '
//     },
//     {
//         icon: 'flaticon-university-graduate-hat',
//         title: 'Education Law',
//         content: 'There are many variations of passages of Lorem '
//     },
//     {
//         icon: 'flaticon-house',
//         title: 'Real Estate Law',
//         content: 'There are many variations of passages of Lorem '
//     },
// ]

const breadcumbMenu = [{ name: 'Home', route: '/' }, { name: 'About us' }];

const AboutPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        {/* <HeaderTop className="headerTop" /> */}
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="About Us"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      {/* <Service className="serviceArea mt-0" /> */}
      <About
        className="aboutArea aboutAreaStyleTwo"
        title="Khat Al Harir"
        subTitle="About us"
        images={about}
        orderLast="order-last"
        videoId="XxVg_s8xAms"
        pragraphs={aboutText}
      />
      {/* <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            /> */}
      {/* <Testmonial
                className="testmonialArea pt100"
            /> */}
      {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            /> */}
      {/* <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            /> */}
      /
      {/* <NewsLetter
                className="newsLetterArea"
            /> */}
      <FooterArea />
    </Fragment>
  );
};
export default AboutPage;
