import React from 'react';
import './style.scss';

const services = [
  { icon: 'fi flaticon-pen', subtitle: 'All types of', title: 'Pens' },
  { icon: 'fi flaticon-scale', subtitle: 'Get Your', title: 'Notebooks' },
  { icon: 'fi flaticon-network', subtitle: 'Perfect', title: 'Bags' },
  { icon: 'fi flaticon-network', subtitle: 'All of', title: 'Papers' },
  { icon: 'fi flaticon-network', subtitle: 'All of', title: 'Folders' },
  { icon: 'fi flaticon-network', subtitle: 'All of', title: 'Envelops' },
  { icon: 'fi flaticon-network', subtitle: 'All of', title: 'Stapler' },
  { icon: 'fi flaticon-network', subtitle: 'Stationery', title: 'Office' },
];
const Service = (props) => {
  return (
    <div className={props.className}>
      <div className="container">
        <div className="row">
          {services.map((service, i) => (
            <div key={i} className="col-lg-3 col-md-6">
              <div className="serviceItem">
                {/* <div className="serviceIcon">
                  <i className={service.icon}></i>
                </div> */}
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
