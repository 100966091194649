import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo/logo.png';
import './style.scss';
const footerLinks = [
  {
    title: 'Quick Link',
    menus: [
      //   { name: 'Home', route: '/' },
      { name: 'About', route: 'about' },
      { name: 'Contact', route: 'contact' },
      //   { name: 'Recent Case', route: 'case' },
      //   { name: 'Our Blog', route: 'blog' },
    ],
  },
  //   {
  //     title: 'Practice Area',
  //     menus: [
  //       { name: 'Family Law', route: 'home' },
  //       { name: 'Criminal Law', route: 'home' },
  //       { name: 'Parsonal Injury', route: 'home' },
  //       { name: 'Real Estate Law', route: 'home' },
  //       { name: 'Business Law', route: 'home' },
  //     ],
  //   },
  {
    title: 'Contact Us',
    menus: [
      //   { name: 'Head Office Address' },
      { name: 'Iraq - Erbil - Bakhtyaty Street, Decora.' },
      { name: 'Phone: 0750 445 3988' },
      { name: 'Phone: 0750 545 3444' },
      { name: 'Email: info@khatalharir.com' },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
                <p>General Trading and Stationery Supplies/Private Limited</p>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>© 2024 All rights reserved</span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    Designed by{' '}
                    <a
                      href="https://www.glossycode.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Glossy Code
                    </a>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
